import { ComponentPropsContext, SitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { GetStaticProps, NextPage } from 'next';
// This is a custom fallback error if the content managed page doesn't exist
// It can also be inlined in this page instead of in a separate file
import FallbackServerError from 'components/FallbackServerError';
import Layout from 'src/Layout';
import { componentBuilder } from 'temp/componentBuilder';

/**
 * Rendered for 404 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/pages/building-your-application/routing/custom-error#404-page
 */
const NotFoundPage: NextPage<SitecorePageProps> = ({ componentProps, layoutData }) => {
  try {
    // If we don't have "_404" page defined in Sitecore
    if (!layoutData?.sitecore?.route) {
      return <FallbackServerError />;
    }

    return (
      <ComponentPropsContext value={componentProps}>
        <SitecoreContext
          componentFactory={componentBuilder.getComponentFactory()}
          layoutData={layoutData}
        >
          <Layout layoutData={layoutData} headLinks={null} />
        </SitecoreContext>
      </ComponentPropsContext>
    );
  } catch (e) {
    console.log(e);
    return <FallbackServerError />;
  }
};

/** 404 page works different from _error and getStaticProps works just fine */
export const getStaticProps: GetStaticProps = async ({ locale }) => {
  if (process.env.DISABLE_SSG_FETCH || locale?.toLocaleLowerCase() === 'default') {
    return { props: {} };
  }
  // We can call layout service directly here unlike in _error since this only runs on the server
  const props = await sitecorePagePropsFactory.create({
    params: {
      path: ['_404'],
    },
    locale: locale,
  });
  return { props };
};

export default NotFoundPage;
